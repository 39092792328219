import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Container, Typography } from '@mui/material';

const EtaEnrollmentDistribution = ({ enrollmentData,title }) => {
    return (
        <Container maxWidth="lg" sx={{ ml: 0, mt: 10, width: 750 }}>
            <Typography ml={7} variant="h7">
                <b>{title}</b>
            </Typography>
            <ResponsiveContainer width="100%" height={350}>
                <BarChart
                    data={enrollmentData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                    <XAxis dataKey="enrollment_type" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="count" fill="#3F51B5" />
                </BarChart>
            </ResponsiveContainer>
        </Container>
    );
};

export default EtaEnrollmentDistribution;

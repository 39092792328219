import React from 'react';
import StudentCenterTable from './StudentCenterTable'; // Adjust the import path as needed
import Button from '@mui/material/Button';

const StudentPage = () => {
  return (
    <div>
         <StudentCenterTable />
    </div>
  );
};

export default StudentPage;

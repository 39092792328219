import React, { useState } from 'react';
import {
  CircularProgress,
  Container,
  Typography,
  Button,
  Input,
  InputAdornment,
  Grid,
  Card,
  CardContent,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Snackbar,
  Alert,
  AlertTitle,
  List, ListItem, ListItemText,  Chip,

  IconButton, TablePagination,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoIcon from '@mui/icons-material/Info';

const Upload = () => {
  const [file, setFile] = useState(null);
  const [uploadErrors, setUploadErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const authToken = localStorage.getItem('authToken');
  const [columnError, setColumnError] = useState('');
  const [serverError, setServerError] = useState('');

  const [page, setPage] = useState(0);
const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setUploadErrors([]);
    setSuccessMessage('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    setColumnError(''); // Reset column error state

    const formData = new FormData();
    formData.append('file', file);

    fetch('https://eap.ethernet.edu.et/api/upload-students-eta', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${authToken}`,
      },
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        setIsLoading(false);
        if (data.errors) {
          if (data.errors.original && data.errors.original.error) {
            // Handle column errors
            setColumnError(data.errors.original.error);
            setUploadErrors([]); // Clear other errors if column error is present
          } else if (Array.isArray(data.errors)) {
            // Handle row-specific errors
            setColumnError(''); // Clear column error if row-specific errors are present
            setServerError(''); // Reset column error state

            const formattedErrors = data.errors.map(error => {
              const errorParts = error.split(': ');
              return { row: errorParts[0], message: errorParts[1] };
            });
            setUploadErrors(formattedErrors);
          }
        } else if  (data.message) {
          // If the backend returns a success message
          setSuccessMessage(data.message); // Set the success message
          setUploadErrors([]); // Clear any existing errors
          setColumnError(''); // Clear any column errors if present
          setServerError(''); // Reset column error state

          setFile(null); // Clear the selected file
        } else {
          // Handle possible errors if the success message is not present
          console.error('Unexpected response structure from the backend.');
          setServerError("Unexpected response structure from the backend.");

        }
      })
      
      .catch(error => {
        setIsLoading(false);
        console.error('Upload failed:', error);
      });

  };

  const handleCloseSnackbar = () => {
    setSuccessMessage('');
  };

  const instructions = [
    "Ensure you have used the required data template.",
    "Each column on the template should have a data",
    "Ensure you didn't miss any of the data parameters needed.",
    "Ensure the file is in .xlsx format before uploading.",
    "In case of errors on your template , Please follow the instructions that the system gives you.",
     <span>Please find the file template <a href="https://eap.ethernet.edu.et/data_template_eta.xlsx" target="_blank" rel="noopener noreferrer">here</a> to upload.</span>

  ];

  return (
    <Container component="main" maxWidth="lg" sx={{ mb: 4, mt: 3,ml:2 }}>
      <Typography variant="h5" gutterBottom>
        Upload Student Records on behalf of private institution
      </Typography>
      <Alert  severity="info" sx={{ width: '55%', ml: 3 ,mt:6}}>
   <strong>Download data template here <a href="https://eap.ethernet.edu.et/data_template_eta.xlsx" target="_blank" rel="noopener noreferrer">Data Template</a> 
   </strong>
</Alert>

      {/* Instructions Card */}
      <Grid container spacing={5} sx={{ mt: 4, padding: 2, mr: 10 }}>
  {/* Instructions Card - now takes 7 columns on medium screens and up */}
  <Grid item xs={12} md={7} sx={{ mb: 2 }}>
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>Instructions</Typography>
        <List dense>
          {instructions.map((instruction, index) => (
            <ListItem key={index}>
              <Chip icon={<InfoIcon />} label={instruction} variant="outlined" sx={{ mb: 1 }} />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  </Grid>

  {/* Upload Card - now takes 5 columns on medium screens and up */}
  <Grid item xs={12} md={5}>
    <Card variant="outlined">
      <CardContent>
        <Input
          type="file"
          disabled={isLoading}
          variant="contained"
          color="primary"
          onChange={handleFileChange}
          fullWidth
          sx={{ marginBottom: 2 }}
          inputProps={{ accept: ".xlsx" }}
          startAdornment={
            <InputAdornment position="start">
              <FileUploadIcon />
            </InputAdornment>
          }
        />
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={!file || isLoading}
          sx={{ width: '100%', height: '40px' }} // Match the height of the Input component
          startIcon={isLoading ? <CircularProgress size={24} /> : <CloudUploadIcon />}
        >
          {isLoading ? 'Uploading...' : 'Upload'}
        </Button>
      </CardContent>
    </Card>
  </Grid>
</Grid>


      {/* Success Message Snackbar */}
      {successMessage && (
        <Snackbar open={Boolean(successMessage)} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert severity="success" sx={{ width: '100%' }} icon={<CheckCircleOutlineIcon />}>
            {successMessage}
          </Alert>
        </Snackbar>
      )}

{serverError && (
        <Alert severity="error" sx={{ width: '50%', mt: 2 }}>
         Uploaded file is empty or contains invalid data template, Reload the page and try again
        </Alert>
      )}
      {columnError && (
        <Alert severity="error" sx={{ width: '50%', mt: 2 }}>
          {columnError}
        </Alert>
      )}
      {/* Upload Errors Table */}
      {uploadErrors.length > 0 && (
        <container>
 <Alert severity="warning" sx={{ width: '100%', mt:7}}>
 <AlertTitle>Error </AlertTitle>
 Your file submission have one or more errors to be corrected pelease refer the below table to identify them
</Alert>

<TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Error Row Number</TableCell>
              <TableCell>Error Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uploadErrors
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((error, index) => (
                <TableRow key={index}>
                  <TableCell>{error.row}</TableCell>
                  <TableCell sx={{color:"red"}}>{error.message}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={uploadErrors.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0); // Reset to the first page
        }}
      />
        </container>
      )}
    </Container>
  );
};

export default Upload;

import React, { useEffect, useState } from 'react';
import Rolepage from './RoleLists'; // Assuming ExamTopics component is created as per previous instructions
import Button from '@mui/material/Button';
import AlertDialog from './AlertDialog'; // Adjust the import path as necessary

const Rolespage = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <h3>Role Management</h3>
      <Button  onClick={handleOpen}  variant="contained">Add New Role</Button> {/* Placeholder for add button */}
      <Rolepage />


<AlertDialog
        open={open}
        onClose={handleClose}
        title="Temporary Function Disablement"
        message="This function is disabled temporarily."
      />
    </div>
  );
};

export default Rolespage;

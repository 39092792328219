import React, { useState, useEffect } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';

const StudentTable = () => {
  const [students, setStudents] = useState([]);
  const [inistname, setInist] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchStudents = async () => {
      const authToken = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://eap.ethernet.edu.et/api/getmycenterstudents ', {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Accept': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          setStudents(data.students);
          setInist(data.institution_name); // Adjust according to your data structure
        } else {
          console.error('Failed to fetch students');
        }
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    };

    fetchStudents();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (studentId) => {
    console.log(`Edit Student ${studentId}`);
    // Implement edit functionality
  };

  const handleRemove = (studentId) => {
    console.log(`Remove Student ${studentId}`);
    // Implement remove functionality
  };

  const handleDownload = () => {
    // Convert students data to CSV
    const csvRows = [];
    const headers = ["Institution Name", "Student ID", "Username", "First Name", "Last Name", "Grandfather Name", "Username", "Exam Topic", "Gender", "Enrollment Type", "Exam Center", "Is Blind", "Is Deaf", "Year"];
    csvRows.push(headers.join(','));

    students.forEach(student => {
        const row = [
            `"${student.institution_namee}"`, // Encapsulated in quotes
            `"${student.student_id}"`,
            `"${student.username}"`,
            `"${student.first_name}"`,
            `"${student.father_name}"`,
            `"${student.grand_father_name}"`,
            `"${student.username}"`, // Username is duplicated in your original headers, ensure this is intentional
            `"${student.exam_topic_name}"`,
            `"${student.gender}"`,
            `"${student.enrollment}"`,
            `"${student.exam_center_name}"`,
            `"${student.is_blind}"`,
            `"${student.is_deaf}"`,
            `"${student.year}"`,
        ];
        csvRows.push(row.join(','));
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    // Create a temporary link to trigger the download
    const link = document.createElement('a');
    link.href = url;
    let filename = "List of students.csv"; // Adjust the filename as needed
    link.download = filename;
    link.click();

    // Clean up
    URL.revokeObjectURL(url);
};


  return (
    <>
      <div>
        <h3>Students placed at my center</h3>

        <Button variant="contained" onClick={handleDownload} sx={{mb:3}} >
  Download list
</Button>


        <TableContainer component={Paper}>
          <Table aria-label="student table">
            <TableHead>
              <TableRow>
                <TableCell><b>Username</b></TableCell>
                <TableCell  ><b>Student ID</b></TableCell>
                <TableCell  ><b>Institution Name</b></TableCell>

                <TableCell  ><b>First Name</b></TableCell>
                <TableCell> <b>Father's Name</b></TableCell>
                <TableCell> <b>Grand Father's Name</b></TableCell>
                <TableCell> <b>Gender</b></TableCell>
                <TableCell> <b>Enrollment Type</b></TableCell>
                 <TableCell> <b>Exam Topic</b></TableCell>
                <TableCell> <b>Exam Center</b></TableCell>
                <TableCell> <b></b></TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {students.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((student) => (
                <TableRow key={student.id}>
                   <TableCell  >{student.username}</TableCell>
                  <TableCell  >{student.student_id}</TableCell>


                  <TableCell>{student.institution_namee}</TableCell>

                  <TableCell>{student.first_name}</TableCell>
                  <TableCell>{student.father_name}</TableCell>
                  <TableCell>{student.grand_father_name}</TableCell>
                  <TableCell>{student.gender}</TableCell>
                  <TableCell>{student.enrollment}</TableCell>
 
                  <TableCell>{student.exam_topic_name}</TableCell>
                  <TableCell>{student.exam_center_name}</TableCell>


                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={students.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default StudentTable;

import React, { useState, useEffect } from 'react';
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Slide, IconButton } from '@mui/material';
import WarningIcon from '@mui/icons-material/WarningAmber';
import CloseIcon from '@mui/icons-material/Close';
import { useUser } from '.././components/userContext';
 
  const ExamTopicsTable = () => {
  const user = useUser(); // Access user context

  const [examTopics, setExamTopics] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {

      
      const fetchExamTopics = async () => {
      const authToken = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://eap.ethernet.edu.et/api/institutions-list', {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Accept': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          setExamTopics(data); // Assuming the response is the array of exam topics
        } else {
          console.error('Failed to fetch exam topics');
        }
      } catch (error) {
        console.error('Error fetching exam topics:', error);
      }
    };

    fetchExamTopics();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (centerId) => {
    console.log(`Edit Center ${centerId}`);
    // Implement edit functionality
  };

  const handleRemove = (centerId) => {
    console.log(`Remove Center ${centerId}`);
    // Implement remove functionality
  };

  const handleDialogOpen = () => {
     setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);

   };

   const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });



  return (
    <>
      <TableContainer component={Paper} sx={{mt:5}}>
        <Table sx={{ minWidth: 500, }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><b>Name</b></TableCell>
              <TableCell  ><b>Code</b></TableCell>
              <TableCell  ><b>Type</b></TableCell>
              {user && (user.role === 'super_admin'  ) && (
            <TableCell><b>Actions</b></TableCell>
          )}

            </TableRow>
          </TableHead>
          <TableBody>
            {examTopics.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((topic) => (
              <TableRow key={topic.id}>
                <TableCell>{topic.name}</TableCell>
                <TableCell  >{topic.code}</TableCell>
                <TableCell>{topic.type}</TableCell>

                {user && (user.role === 'super_admin'  ) && (
           
                <TableCell>
                <Button sx={{mr:2}} variant="outlined" onClick={() => handleDialogOpen()} color="primary">
                  Edit
                </Button>
                <Button  variant="outlined" onClick={() => handleDialogOpen()} color="secondary">
                  Remove
                </Button>
              </TableCell>
               )}

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={examTopics.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />

<Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Slide}
      >
        <DialogTitle id="alert-dialog-title">{"Disabled function"}
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <WarningIcon sx={{ color: 'red', mr: 2, fontSize: 40 }} />
            This fucntion disabled temporarily 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" variant="outlined">
            Ok
          </Button>
          
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExamTopicsTable;

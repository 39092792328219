import React, { useState, useEffect } from 'react';
import { LinearProgress, Alert, AlertTitle, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Box, Typography, Container, MenuItem, FormControl, InputLabel, Select, FormHelperText, Snackbar } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

import axios from 'axios';


// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const isValidName = (name) => /^[a-zA-Z\s]+$/.test(name);
const isValidEmail = (email) => /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
const isValidPhone = (phone) => /^\+[1-9]\d{1,14}$/.test(phone);



export default function SignUp() {

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0); // Track upload progress
  const [isUploading, setIsUploading] = useState(false); // Track uploading state
  const [consentTouched, setConsentTouched] = useState(false);
  const [selectedInstitution, setSelectedInstitution] = useState('');
  const [gender, setGender] = useState('');

  const [fields, setFields] = useState({
    firstName: '',
    fatherName: '',
    grandFatherName: '',
    email: '',
    phoneNumber: '',
    file: null,
  });
  const [errors, setErrors] = useState({});


  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: files ? files[0] : value,
    }));

    // Validate fields on change
    validateField(name, value, files);
  };

  const validateField = (name, value, files = null) => {
    let errorMsg = '';

    switch (name) {
      case 'firstName':
      case 'fatherName':
      case 'grandFatherName':
        if (!value) errorMsg = 'This field is required';
        else if (!isValidName(value)) errorMsg = 'Invalid name. Only letters and spaces are allowed';
        break;
      case 'email':
        if (!value) errorMsg = 'This field is required';
        else if (!isValidEmail(value)) errorMsg = 'Invalid email format';
        break;
      case 'phoneNumber':
        if (!value) errorMsg = 'This field is required';
        else if (!isValidPhone(value)) errorMsg = 'Invalid phone number. Must start with + and include country code';
        break;
      case 'file':
        if (!files) errorMsg = 'File is required';
        break;
      default:
        break;
    }

    setErrors((prev) => ({ ...prev, [name]: errorMsg }));
  };
  const defaultTheme = createTheme({
    components: {
      // Style overrides for TextField
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-input': {
              height: '20px', // Adjust the height as needed
            },
          },
        },
      },
      // Style overrides for Select
      MuiSelect: {
        styleOverrides: {
          select: {
            height: '20px', // Adjust the height as needed
          },
        },
      },
      // You can add more overrides as needed
    },
    palette: {
      primary: {
        main: 'rgb(18,82,166)',
      },
    },
    typography: {
      fontFamily: '"Times New Roman", Times, serif',
    },
  });




  const [loading, setLoading] = useState(true);


  useEffect(() => {
    fetch('https://eap.ethernet.edu.et/api/signup-inist')
      .then(response => response.json())
      .then(data => {
        setInstitutions(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching institutions:', error);
        setLoading(false);
      });
  }, []);

  const [institutions, setInstitutions] = useState([]);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [file, setFile] = useState(null);
  const [consent, setConsent] = useState(false);
  const [formErrors, setFormErrors] = useState({});



  const handleSubmit = async (event) => {

    event.preventDefault();


    setIsUploading(true);

    const formData = new FormData();
    formData.append('firstName', event.target.firstName.value);
    formData.append('fatherName', event.target.fatherName.value);
    formData.append('grandFatherName', event.target.grandFatherName.value);
    formData.append('email', event.target.email.value);
    formData.append('phoneNumber', event.target.phoneNumber.value);
    formData.append('gender', gender);
    formData.append('institutionId', selectedInstitution);
    if (file) formData.append('file', file);
    formData.append('consent', consent);


    console.log(selectedInstitution);

    try {
      // Use axios for the POST request
      const response = await axios({
        method: 'post',
        url: 'https://eap.ethernet.edu.et/api/signup',
        data: formData,
        // No need for Authorization header for signup as per your requirement
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      });

      if (response.status === 200) {
        // Handle success
        setSnackbarMessage('Your request has been sent. You will receive an email containing your password upon approval.');
        setOpenSnackbar(true);
        setUploadProgress(0);

        setFormErrors({}); // Clear any previous errors
        setFields({
          firstName: '',
          fatherName: '',
          grandFatherName: '',
          email: '',
          phoneNumber: '',
          file: null,
        });
        setSelectedInstitution('');
        setGender('');
        setConsent(false);
        setConsentTouched(false); // Reset the consent touched state
      }
    } catch (error) {
      if (error.response && error.response.data) {
        // Backend returned an error response
        setFormErrors(error.response.data); // Assuming errors are returned in this structure
        setSnackbarMessage('Please correct the errors and try again.');
        setOpenSnackbar(true);
      } else {
        // Other errors like network issues
        setSnackbarMessage('An unexpected error occurred. Please try again later.');
        setOpenSnackbar(true);
      }
    }

    setIsUploading(false); // Indicate uploading ends
    setUploadProgress(0); // Reset upload progress
  };

  const [validationErrors, setValidationErrors] = useState({});

  // Utility function to validate name fields for non-numeric input
  const isValidName = (value) => /^[A-Za-z ]+$/.test(value);
  const isValidEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  // Utility function to validate phone number (assuming international format)
  const isValidPhoneNumber = (value) => {
    const phoneRegex = /^\+[1-9]\d{1,14}$/;
    return phoneRegex.test(value);
  };

  // Modify the validateField function to include checks for email and phone number



  const handleInstitutionChange = (event) => {
    setSelectedInstitution(event.target.value);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleConsentChange = (event) => {
    setConsent(event.target.checked);
    setConsentTouched(true); // Add this line
  };

  // Render loading indicator while fetching institutions
  if (loading) {
    return <div>Loading institutions...</div>;
  }
  return (


    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{

            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          <Typography component="h1" variant="h6">

            <b>Sign up to be an institution representative</b>
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}  >
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  value={fields.firstName}

                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  onChange={handleChange}

                  error={!!formErrors.firstName}
                  helperText={formErrors.firstName ? formErrors.firstName.join(', ') : ''}
                />

              </Grid>
              <Grid item xs={12}  >
                <TextField
                  required
                  fullWidth
                  value={fields.fatherName}

                  id="fatherName"
                  label="Father Name"
                  name="fatherName"
                  onChange={handleChange}

                  error={!!formErrors.fatherName} // Show error style if there's an error for firstName
                  helperText={formErrors.fatherName ? formErrors.fatherName[0] : formErrors.fatherName} // Show the first error message

                />
              </Grid>
              <Grid item xs={12}  >
                <TextField
                  required
                  fullWidth
                  id="grandFatherName"
                  label="Grand Father Name"
                  name="grandFatherName"
                  value={fields.grandFatherName}
                  onChange={handleChange}

                  error={!!formErrors.grandFatherName} // Show error style if there's an error for firstName
                  helperText={formErrors.grandFatherName ? formErrors.grandFatherName[0] : formErrors.grandFatherName} // Show the first error message

                />
                <Grid item xs={12} sx={{ mt: 5 }}>
                  <FormControl fullWidth error={!!formErrors.institutionId}>
                    <InputLabel id="institution-label">Institution</InputLabel>
                    <Select
                      labelId="institution-label"
                      id="institution-select"
                      value={selectedInstitution}
                      onChange={handleInstitutionChange}
                      name="Institution"
                    >
                      {institutions.map((institution) => (
                        <MenuItem key={institution.id} value={institution.id}>
                          {institution.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formErrors.institutionId && (
                      <FormHelperText>{formErrors.institutionId[0]}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <FormHelperText>Choose your institution</FormHelperText>
                {/* Include other form fields and a submit button here */}

              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="gender-label">Gender</InputLabel>
                  <Select
                    labelId="gender-label"
                    id="gender"
                    value={gender}
                    label="Gender"
                    onChange={handleGenderChange}
                    error={!!formErrors.gender} // Set to true if there's an error for firstName
                    helperText={formErrors.gender ? formErrors.gender[0] : ''} // Show the first error message for firstName


                  >
                    <MenuItem value={'male'}>Male</MenuItem>
                    <MenuItem value={'female'}>Female</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Email field */}
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={fields.email}
                  onChange={handleChange}

                  error={!!formErrors.email} // Set to true if there's an error for firstName
                  helperText={formErrors.email ? formErrors.email[0] : ''} // Show the first error message for firstName


                />
              </Grid>

              {/* Phone number field */}
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="phoneNumber"
                  label="Phone Number"
                  name="phoneNumber"
                  autoComplete="tel"
                  error={!!formErrors.phoneNumber} // Set to true if there's an error for firstName
                  helperText={formErrors.phoneNumber ? formErrors.phoneNumber[0] : ''} // Show the first error message for firstName
                  value={fields.phoneNumber}
                  onChange={handleChange}

                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="file"
                  fullWidth
                  id="file"
                  name="file"
                  InputLabelProps={{ shrink: true }}
                  onChange={handleFileChange}
                  error={!!formErrors.file} // Set to true if there's an error for firstName
                  helperText={formErrors.file ? formErrors.file[0] : ''} // Show the first error message for firstName


                />
              </Grid>
              <Container component="main" maxWidth="xs">
                {/* Your form setup... */}

                {/* Displaying upload progress and label */}
                {uploadProgress > 0 && (
                  <Box display="flex" alignItems="center" width="100%" mt={2}>
                    <Box width="100%" mr={1}>
                      <LinearProgress variant="determinate" value={uploadProgress} />
                    </Box>
                    <Box minWidth={35}>
                      <Typography variant="body2" color="textSecondary">{`${uploadProgress}%`}</Typography>
                    </Box>
                  </Box>
                )}
                {isUploading && <Typography variant="body2" style={{ textAlign: 'center', marginTop: '10px' }}>Uploading in progress...</Typography>}


              </Container>


              {/* Consent checkbox */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox checked={consent} onChange={handleConsentChange} color="primary" />}
                  label="I agree that the above information provided are true."
                />
                {!consent && consentTouched && (
                  <FormHelperText error={true}>You must agree to the terms to proceed.</FormHelperText>
                )}
              </Grid>

            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isUploading} // Disable button during upload

            >
              Sign Up
            </Button>

            <Grid item>
              {/* Toggle the view to SignUp component */}
              <Link
                variant="body2"
                style={{ cursor: 'pointer' }}
                onClick={() => window.location.reload()}
              >
                {"You have an account? Sign In"}
              </Link>

            </Grid>
          </Box>
        </Box>
      </Container>
      <Snackbar open={openSnackbar} autoHideDuration={8000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity={formErrors && Object.keys(formErrors).length > 0 ? "error" : "success"} sx={{ width: '100%' }}>
          <AlertTitle>{formErrors && Object.keys(formErrors).length > 0 ? "Error" : "Success"}</AlertTitle>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );


}

import React, { useState, useEffect } from 'react';
import { Box, Grid, Snackbar, Alert, AlertTitle } from '@mui/material';
    import StatisticsCard from './StatisticsCard';
import PeopleIcon from '@mui/icons-material/People';
import SchoolIcon from '@mui/icons-material/School';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import ProgramStudentsCountTableEta from './ProgramsStudetnsCountTableEta'; // Importing the table component
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import GenderRatio from './GenderRatioChart';

import EnrollmentDistribution from './EtaEnrollmentDistribution';
 

const Dashboard = () => {
    const [stats, setStats] = useState({
        totalStudents: 0,
        numberOfPrograms: 0,
        maleStudents: 0,
        femaleStudents: 0,
        approval_stat: 0,
    });

    useEffect(() => {
        const fetchStatistics = async () => {
            const authToken = localStorage.getItem('authToken');
            try {
                const response = await fetch('https://eap.ethernet.edu.et/api/etastatistics', {
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setStats(data);
                } else {
                    console.error('Failed to fetch statistics');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchStatistics();
    }, []);

    const getAlertMessage = () => {

        if (stats.type === "Private") {

            switch (stats.approval_stat) {
                case 0:
                    return { severity: "info", title: "Pending", message: "Your submission is pending for approval, Please follow up ETA." };
                case 1:
                    return { severity: "success", title: "Approved", message: "Your records have been approved." };
                case 2:
                    return { severity: "warning", title: "Rejected", message: "Your record submission has been rejected, Please contact ETA ASAP." };
                default:

                    return null;
            }
        }
    };

    const alertMessage = getAlertMessage();

    return (
        <Box sx={{ flexGrow: 1, mt: 3, ml: 0 }}>
            {/* Statistics Cards */}
            <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 4, ml: 2 }}>
                <StatisticsCard title="Total Number of Institutions" value={stats.totalPrivateInstitutionsCount} icon={<AccountBalanceIcon />} color="error.main" />
                <StatisticsCard title="Total Number of Approved Institutions" value={stats.numberOfApprovedInitutions} icon={<CheckBoxIcon />} color="info.main" />
                <StatisticsCard title="Total Number of Programs" value={stats.numberOfPrograms} icon={<SchoolIcon />} color="secondary.main" />
                <StatisticsCard title="Total number of Students" value={stats.totalStudents} icon={<PeopleIcon />} color="primary.main" />
            </Box>

            {/* Responsive layout for the table and pie chart */}
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <ProgramStudentsCountTableEta  title="Gender Distribution (Private Institutions)" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <GenderRatio maleStudents={stats.maleStudents} femaleStudents={stats.femaleStudents}     title="Gender Distribution (Private Institutions)" 
/>
                </Grid>

                <EnrollmentDistribution enrollmentData={stats.enrollmentCounts} title="Enrollment Distribution (Private Institutions)"/>

            </Grid>

            {alertMessage && (
                <Snackbar open={true} autoHideDuration={alertMessage.severity === "warning" ? null : 6000}>
                    <Alert severity={alertMessage.severity} sx={{ width: '100%' }}>
                        <AlertTitle>{alertMessage.title}</AlertTitle>
                        {alertMessage.message}
                    </Alert>
                </Snackbar>
            )}
        </Box>
    );
};

export default Dashboard;

import React, { useEffect, useState } from 'react';
import AllStudentList from './AllStudentsList'; // Assuming ExamTopics component is created as per previous instructions
import Button from '@mui/material/Button';

const Rolespage = () => {
  return (
    <div>
      <h3>All student list</h3>
       <AllStudentList />
    </div>
  );
};

export default Rolespage;

import React, { useEffect, useState } from 'react';
import { Table,Container, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, TablePagination,Button } from '@mui/material';

const PrivateInstitutionsTable = () => {
  const [institutions, setInstitutions] = useState({
    data: [],
    page: 0,
    totalPages: 0,
    perPage: 5
  });

  useEffect(() => {
    const fetchData = async () => {
      const authToken = localStorage.getItem('authToken');
      try {
        const response = await fetch(`https://eap.ethernet.edu.et/api/inist-stat-public?page=${institutions.page + 1}`, {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        });
        if (response.ok) {
          const jsonData = await response.json();
          setInstitutions(prev => ({
            ...prev,
            data: jsonData.data,
            totalPages: jsonData.total,
            perPage: jsonData.per_page
          }));
        } else {
          console.error("Failed to fetch institutions");
        }
      } catch (error) {
        console.error("Error fetching institutions:", error);
      }
    };
    fetchData();
  }, [institutions.page]);

  const handleChangePage = (event, newPage) => {
    setInstitutions(prev => ({ ...prev, page: newPage }));
  };


  

 

  const handleDownload = async () => {
    const authToken = localStorage.getItem('authToken');
    const csvRows = [
      ["Institution Name", "Representative Name", "Email", "Phone"] // Headers
    ];
  
    let allDataCollected = false;
    let currentPage = 1;
    while (!allDataCollected) {
      const response = await fetch(`https://eap.ethernet.edu.et/api/inist-stat-public?page=${currentPage}`, {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });
  
      if (response.ok) {
        const jsonData = await response.json();
        jsonData.data.forEach(institution => {
          institution.users.forEach(user => {
            csvRows.push([
              institution.name,
              user.first_name + ' ' + user.last_name,
              user.email,
              user.phone
            ]);
          });
        });
  
        currentPage += 1;
        if (currentPage > jsonData.total_pages) {
          allDataCollected = true;
        }
      } else {
        console.error("Failed to fetch more data");
        allDataCollected = true; // Stop loop in case of error
      }
    }
  
    // Convert array to CSV string
    const csvString = csvRows.map(e => e.join(',')).join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
  
    const link = document.createElement('a');
    link.href = url;
    link.download = 'PublicInstitutions.csv';
    document.body.appendChild(link);
    link.click();
  
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  
  return (

    <Container maxWidth={false} sx={{ width: '100%', padding: 0 }}>   
    <Typography variant="h7" sx={{ my: 2,}}><b>Public Institutions</b>
    
 
    </Typography>

      
    <TableContainer component={Paper} sx={{ maxWidth: '100%', margin: 'auto', mt: 4 }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell><b>Institution Name</b></TableCell>
            <TableCell align="right"><b>Represenative name</b></TableCell>
            <TableCell align="right"><b>Email</b></TableCell>
            <TableCell align="right"><b>Phone</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {institutions.data.map((institution) => (
            <TableRow key={institution.id}>
              <TableCell component="th" scope="row">{institution.name}</TableCell>
              {institution.users && institution.users.length > 0 ? institution.users.map((user) => (
                <React.Fragment key={user.id}>
                  <TableCell align="right">{user.first_name}</TableCell>
                  <TableCell align="right">{user.email}</TableCell>
                  <TableCell align="right">{user.phone}</TableCell>
                </React.Fragment>
              )) : (
                <>
                  <TableCell align="right">No User Data</TableCell>
                  <TableCell align="right">-</TableCell>
                  <TableCell align="right">-</TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={institutions.totalPages}
        page={institutions.page}
        onPageChange={handleChangePage}
        rowsPerPage={institutions.perPage}
        rowsPerPageOptions={[5]}
      />
    </TableContainer>
    </Container>
  );
};

export default PrivateInstitutionsTable;

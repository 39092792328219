// StatisticsCard.js

import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';

const StatisticsCard = ({ title, value, icon, color }) => {
  return (
    <Card sx={{ width: 300, margin: 1, bgcolor: color, color: '#fff', mr:3 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} gutterBottom>
          {title}
        </Typography>
        <Box sx={{ display: 'flex',  justifyContent: 'space-between' ,ml:0}}>
          <Typography variant="h5" component="div">
            {value}
          </Typography>
          {icon}
        </Box>
        
      </CardContent>
    </Card>
  );
};

export default StatisticsCard;

import React, { useState, useEffect } from 'react';
import { Box, Grid, Container } from '@mui/material';
import StatisticsCard from '../pages/StatisticsCard';
import PublicIcon from '@mui/icons-material/Public';
import LockIcon from '@mui/icons-material/Lock';
import SchoolIcon from '@mui/icons-material/School';
import PeopleIcon from '@mui/icons-material/People';
import DataProgressTable from '../pages/dataprogresstable';
import ProgressStatpublic from './inistStatsitcsPublic';
import ProgressStatprivate from './inistStatsitcsPrivate';
import GenderRatio from '../pages/GenderRatioChart';
import PendingPrivateList from '../pages/RecordApprovalList';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import ProgramStudentsCountTableAll from '../pages/ProgramStudentsCountTablAllReExam';

const Dashboard = () => {
    const [stats, setStats] = useState({
        totalPublicInstitutions: 0,
        totalPublicInstitutionsWithRecords: 0,
        totalPrivateInstitutions: 0,
        totalPrivateInstitutionsWithRecords: 0,
        numberOfPrograms: 0,
        totalStudents: 0,
        approval_stat: 0,
        type: '',
    });

    const fetchStatistics = async () => {
        const authToken = localStorage.getItem('authToken');
        try {
            const response = await fetch('https://exam.ethernet.edu.et/api/allstatistics1414', {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });
            if (response.ok) {
                const data = await response.json();
                setStats(data);
            } else {
                console.error('Failed to fetch statistics');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchStatistics();
        const interval = setInterval(fetchStatistics, 5000); // Refresh every 5 seconds
        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    const formattedValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'ETB',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(stats.paid_count * 500);

    return (
        <Box sx={{ flexGrow: 1, mt: 3, ml: 0 }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 4, ml: 2 }}>
                {/* Card for Total Number of Public Institutions and Those with Records */}
                <StatisticsCard
                    title="Total Registerd"
                    value={`${stats.paid_count}`}
                    icon={<PublicIcon />}
                    color="primary.main"
                />
                {/* Card for Total Number of Private Institutions and Those with Records */}
                <StatisticsCard
                    title="Total Male"
                    value={`${stats.male}`}
                    icon={<MaleIcon />}
                    color="secondary.main"
                />
                {/* Card for Total Number of Programs */}
                <StatisticsCard
                    title="Total Female"
                    value={`${stats.female}`}
                    icon={<FemaleIcon />}
                    color="info.main"
                />
                {/* Card for Total Number of Students */}
                <StatisticsCard
                    title="Service fees"
                    value={formattedValue}
                    icon={<AttachMoneyIcon />}
                    color="warning.main"
                />
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <Container maxWidth={false} sx={{ width: '100%', padding: 0, mt: 2 }}>
                        <ProgramStudentsCountTableAll />
                    </Container>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Dashboard;

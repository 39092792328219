import React, { useEffect, useState } from 'react';
import UserRequestList from './UserRequiestList'; // Assuming ExamTopics component is created as per previous instructions
import Button from '@mui/material/Button';

const ExamTopicsPage = () => {
  return (
    <div>
      <h3>User Registration Request</h3>
       <UserRequestList />
    </div>
  );
};

export default ExamTopicsPage;

import React, { useEffect, useState } from 'react';
import PrivateDataList from './PrivateDataList'; // Assuming ExamTopics component is created as per previous instructions
import Button from '@mui/material/Button';

const UserPage = () => {
  return (
    <div>
      <h3>Records for all valid private institution data</h3>
       <PrivateDataList />
    </div>
  );
};

export default UserPage;

import React from 'react';
import { Box, Typography } from '@mui/material';
import { useUser } from './userContext'; // Adjust the import path to where your UserContext and useUser hook are defined

const MainContent = () => {
  const user = useUser(); // Use the useUser hook to access the current user

  // Ensure user object is not undefined before attempting to access properties
  const welcomeText = user ? `Welcome, ${user.firstname} ${user.fathername}` : 'Welcome';

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' ,mt:-20, ml:-10}}>
      <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold' }}>
        {welcomeText}
      </Typography>
    </Box>
  );
};

export default MainContent;

import React from 'react';
import { PieChart, Pie, Legend, Tooltip, Cell, } from 'recharts';
import { Container ,Typography} from '@mui/material';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
 
const GenderRatio = ({ maleStudents, femaleStudents,title }) => {
    const data = [
        { name: 'Male', value: maleStudents, color: '#0288D1' }, // Orange color for male
        { name: 'Female', value: femaleStudents, color: '#008000' }, // Green color for female
    ];

    return (
        <Container maxWidth="lg" sx={{ ml: 0, mt: 10, width: 750, }} >

            <Typography ml={7} variant="h7">
            <b>{title}</b>

            </Typography>
            <PieChart width={400} height={350} >
                <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={data}
                    cx={200}
                    cy={200}
                    outerRadius={80}
                    label
                >
                    {
                        data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                        ))
                    }
                </Pie>
                <Tooltip />
                <Legend />
            </PieChart>
        </Container>
    );
};

export default GenderRatio;

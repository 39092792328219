import React, { useState, useEffect } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, CircularProgress, Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { TableSortLabel } from '@mui/material';

import { Slide, IconButton } from '@mui/material';
import WarningIcon from '@mui/icons-material/WarningAmber';
import CloseIcon from '@mui/icons-material/Close';

const UserLists = () => {
  const [examTopics, setExamTopics] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ open: false, message: '', severity: 'info' }); // Renamed from alert to alertMessage
  const [resettingRequestID, setresettingRequestID] = useState(null);
  const [removeRequestID, setRemoveRequestID] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const [orderDirection, setOrderDirection] = useState('asc');
  const [orderBy, setOrderBy] = useState('id'); // Default sorting by 'first_name'
 
  useEffect(() => {
    const fetchExamTopics = async () => {
      const authToken = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://eap.ethernet.edu.et/api/user-list', {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Accept': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          setExamTopics(data); // Assuming the response is the array of exam topics
          console.log(data);
        } else {
          console.error('Failed to fetch exam topics');
        }
      } catch (error) {
        console.error('Error fetching exam topics:', error);
      }
    };

    fetchExamTopics();
  }, []);




  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (userID) => {
    console.log(`Edit User ${userID}`);
    // Implement edit functionality
  };

  const handleRemove = async () => {
    setRemoveRequestID(userToDelete.user_id); // Start loading for this specific request
    if (!userToDelete) return;

    try {
      const response = await fetch(`https://eap.ethernet.edu.et/api/removeuser/${userToDelete.user_id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Accept': 'application/json',
        },
      });
      setRemoveRequestID(false);

      if (response.ok) {
        setAlertMessage({ open: true, message: 'User deleted successfully.', severity: 'success' });
        setExamTopics(examTopics.filter(topic => topic.user_id !== userToDelete.user_id));
      } else {
        const data = await response.json();
        setAlertMessage({ open: true, message: data.message || 'Failed to delete user.', severity: 'error' });
      }
    } catch (error) {
      console.error('Error:', error);
      setAlertMessage({ open: true, message: 'Failed to delete user. Please try again.', severity: 'error' });
    } finally {
      setLoading(false);
      handleDialogClose();
    }

  };

  const handleReset = async (email, userid) => {

    console.log(email);

    setresettingRequestID(userid); // Start loading for this specific request

    //setLoading(true); // Assuming you have a setLoading function to manage loading state

    // API call to request password reset link\\

    try {
      const response = await fetch('https://eap.ethernet.edu.et/api/password-reset-request', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      setresettingRequestID(false); // Reset loading state after the fetch call

      if (response.ok) {
        setAlertMessage({ open: true, message: 'Reset link has been sent to user email.', severity: 'success' });
      } else if (response.status === 404) {
        setAlertMessage({ open: true, message: 'Unable to find user email.', severity: 'error' });
      } else {
        const errorMessage = await response.text();
        setAlertMessage({ open: true, message: `Failed to send reset link: ${errorMessage}`, severity: 'error' });
      }

    } catch (error) {
      console.error('Error:', error);
    }
    finally {
      setresettingRequestID(null); // Reset loading state
    }

  };

 






  const handleCloseSnackbar = () => {
    setAlertMessage({ ...alertMessage, open: false });
  };


  const handleDialogOpen = (user) => {
    setUserToDelete(user);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setUserToDelete(null);
  };


  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedData = () => {
    return examTopics.sort((a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return orderDirection === 'asc' ? -1 : 1;
      }
      if (a[orderBy] > b[orderBy]) {
        return orderDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };


  function timeSince(utcDate) {
    const localDate = new Date(utcDate + 'Z'); // Appends 'Z' to indicate UTC time
  const now = new Date();
  const seconds = Math.floor((now - localDate) / 1000);

  let interval = seconds / 31536000;
  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return "Online";
  }
  




  return (
    <>
      <TableContainer component={Paper} sx={{ mt: 5 }}>
        <Table sx={{ minWidth: 500, }} aria-label="simple table">
        <TableHead>
            <TableRow>
              {[
                { id: 'first_name', label: 'First Name', sortable: true },
                { id: 'last_name', label: 'Father Name', sortable: true },
                { id: 'institution', label: 'Institution', sortable: true },
                { id: 'role_name', label: 'Role', sortable: true },
                { id: 'phone', label: 'Phone No.', sortable: true },
                { id: 'email', label: 'Email', sortable: true },
                { id: 'last_login_at', label: 'Last Login', sortable: true },
                { id: 'actions', label: 'Actions', sortable: false }
              ].map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? orderDirection : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? orderDirection : 'asc'}
                    onClick={() => handleRequestSort(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData()
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((topic) => (
                <TableRow key={topic.user_id}>

                <TableCell>{topic.first_name}</TableCell>
                <TableCell>{topic.last_name}</TableCell>
                <TableCell  >{topic.institution}</TableCell>
                <TableCell>{topic.role_name}</TableCell>
                <TableCell>{topic.phone}</TableCell>
                <TableCell>{topic.email}</TableCell>
                <TableCell>{topic.last_login_at ? timeSince(topic.last_login_at) : 'Never'}</TableCell>


                <TableCell>

                  <Button onClick={() => handleDialogOpen(topic)} variant="contained" sx={{ mr: 2, backgroundColor: 'red', '&:hover': { backgroundColor: 'darkred' } }}

                    disabled={topic.role_name === 'super_admin' || topic.role_name === 'officials' || removeRequestID === topic.user_id}  
                  >

                    {removeRequestID === topic.user_id ? <CircularProgress size={24} /> : <DeleteIcon size={24} />}
                  </Button>

                  <Button onClick={() => handleReset(topic.email, topic.user_id)} color="primary" variant="contained"
                    disabled={topic.role_name === 'super_admin' || topic.role_name === 'officials' || resettingRequestID === topic.user_id}

                  >

                    {resettingRequestID === topic.user_id ? <CircularProgress size={24} /> : <VpnKeyIcon size={24} />}

                  </Button>



                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={examTopics.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />

      <Snackbar open={alertMessage.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={alertMessage.severity} sx={{ width: '100%' }}>
          {alertMessage.message}
        </Alert>
      </Snackbar>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Slide}
      >
        <DialogTitle id="alert-dialog-title">{"Confirm User Deletion"}
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <WarningIcon sx={{ color: 'red', mr: 2, fontSize: 40 }} />
            Are you sure you want to delete this user? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleRemove} color="secondary" variant="contained" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>


  );
};

export default UserLists;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, TablePagination, TextField, Box, Container } from '@mui/material';
import AlertDialog from './AlertDialog'; // Adjust the import path as necessary

const StudentTable = () => {
    const [students, setStudents] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [perPage, setPerPage] = useState(5);
    const [search, setSearch] = useState('');
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        const fetchData = async () => {
            const authToken = localStorage.getItem('authToken');
            const response = await fetch(`https://eap.ethernet.edu.et/api/students?search=${search}&page=${page + 1}`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });
            if (response.ok) {
                const jsonData = await response.json();
                setStudents(jsonData.data);
                setTotal(jsonData.total);
                setPerPage(jsonData.per_page);
            } else {
                console.error("Failed to fetch institutions");
            }
        };
        fetchData();
    }, [search, page]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    const handleDetailsClick = (id) => {
        navigate(`/getStudents/${id}`);
    };

    return (
        <Container maxWidth="false" disableGutters>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                <TextField
                    value={search}
                    onChange={handleSearchChange}
                    placeholder="Search by name"
                    size="small"
                    sx={{ width: 250 }}
                />
            </Box>

            <TableContainer component={Paper} sx={{ width: '100%', maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>First Name</b></TableCell>
                            <TableCell><b>Father Name</b></TableCell>
                            <TableCell><b>Grand Father Name</b></TableCell>
                            <TableCell><b>Gender</b></TableCell>
                            <TableCell><b>Enrollment Type</b></TableCell>
                            <TableCell><b>Institution Name</b></TableCell>
                            <TableCell><b>Department</b></TableCell>
                            <TableCell><b>Exam Topic</b> </TableCell>
                            <TableCell><b>Exam Center</b> </TableCell>
                            <TableCell><b>Action</b> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {students.map((student) => (
                            <TableRow key={student.id}>
                                <TableCell>{student.first_name}</TableCell>
                                <TableCell>{student.father_name}</TableCell>
                                <TableCell>{student.grand_father_name}</TableCell>
                                <TableCell>{student.gender}</TableCell>
                                <TableCell>{student.enrollment_type}</TableCell>
                                <TableCell>{student.institution.name}</TableCell>
                                <TableCell>{student.department}</TableCell>
                                <TableCell>{student.examtopic.name}</TableCell>
                                <TableCell>{student.examcenter.name}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => handleDetailsClick(student.id)}
                                    >
                                        Details
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={total}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={perPage}
                />
            </TableContainer>
            <AlertDialog
                open={open}
                onClose={handleClose}
                title="Temporary Function Disablement"
                message="This function is disabled temporarily."
            />
        </Container>
    );
};

export default StudentTable;

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme'; // Adjust this import according to where your theme is defined
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import StudData from './components/StudData';
import MainContent from './components/MainContent';
import Upload from './pages/Upload';
import UploadETA from './pages/UploadETA';
import StudDashBoard from './pages/StudDashBoard';
import ExamTopicPage from './pages/ExamTopicPage';
import ExamCenterPage from './pages/ExamCenterPage';
import UserPage from './pages/UserPage';
import Rolepage from './pages/RolesPage';
import InstitutionPage from './pages/InstitutionPage';
import StudentsPage from './pages/StudentsPage';
import RegistrationRequest from './pages/UserRequestPage';
import ApprovalList from './pages/RecordApprovalPage';
import EtaDashboard from './pages/EtaDashboard';
import MainDashboard from './components/MainDashboard';
import ResetPasswordForm from './components/ResetPassword';
import Datasubmission from './pages/datasubmission';
import Allstudentpage from './pages/AllStudentsPage';
import PublicDataPage from './pages/PublicDataPage';
import PrivateDataPage from './pages/PrivateDatePage';
import Reexamprogress from './pages/ReexamRegistratoinProgress';
import NgatRegistration from './pages/NgatRegistration';
import InsufficientPrivileage from './pages/InsufficientPrivileage';
import UsersLog from './pages/UserLogPage';
import StudentProfile from './pages/StudentProfile';



import StudDataCenter from  './components/StudentDataCenter'
import { useUser } from './components/userContext';


import Login from './components/Login';
import Box from '@mui/material/Box';
import { isAuthenticated } from './utils/auth';
import { useState, useEffect } from 'react';
import { UserProvider } from './components/userContext';



function App() {
  const [authStatus, setAuthStatus] = useState(isAuthenticated());

  useEffect(() => {
    const handleStorageChange = () => {
      setAuthStatus(isAuthenticated());
    };
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <UserProvider>
        <AppContent authStatus={authStatus} />
      </UserProvider>
    </ThemeProvider>
  );
}

function AppContent({ authStatus }) {
  const user = useUser(); // Access user context

  const isAuthorized = (requiredRole) => {
    // Check if user exists and has a role property
    if (user && user.role) {
      console.log(user.role);

      // Check if the user has the required role to access the route
      return user.role === requiredRole;
    }


    // If user or user.role is undefined, return false
    return false;
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <UserProvider>
        <Router>
          {authStatus ? (
            <>
              <Header />
              <Box sx={{ display: 'flex' }}>
                <Sidebar />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                  <Routes>
                    <Route path="/" element={<MainContent />} />
                    <Route path="/examtopics" element={<ExamTopicPage />} />
                    <Route path="/examcenters" element={<ExamCenterPage />} />
                    <Route path="/reset-password/:token" element={<ResetPasswordForm />} />


                    {isAuthorized('university_user') ? (
                      <>
                        <Route path="/upload" element={<Upload />} />
                        <Route path="/studentlist" element={<StudData />} />
                        <Route path="/getmycenterstudents" element={<StudDataCenter />} />
                        <Route path="/studdasboard" element={<StudDashBoard />} />

                        

                      </>
                    ) : (
                      <>
                        <Route path="/upload" element={<InsufficientPrivileage />} />
                        <Route path="/studentlist" element={<InsufficientPrivileage />} />
                        <Route path="/getmycenterstudents" element={<InsufficientPrivileage />} />

                        <Route path="/studdasboard" element={<InsufficientPrivileage />} />


                      </>
                    )}




                    {isAuthorized('super_admin') ? (
                      <>
                        <Route path="/rolelist" element={<Rolepage />} />
                        <Route path="/users-log" element={<UsersLog />} />
                      </>
                    ) : (
                      <>
                        <Route path="/rolelist" element={<InsufficientPrivileage />} />
                        <Route path="/users-log" element={<InsufficientPrivileage />} />


                      </>
                    )}

                     {isAuthorized('super_admin') ? (
                      <>
                        <Route path="/rolelist" element={<Rolepage />} />
                        <Route path="/users-log" element={<UsersLog />} />
                        <Route path="/getStudents/:id" element={<StudentProfile />} />
                        <Route path="/ngatregistration" element={<NgatRegistration />} />


                      </>
                    ) : (
                      <>
                        <Route path="/rolelist" element={<InsufficientPrivileage />} />
                        <Route path="/users-log" element={<InsufficientPrivileage />} />
                        <Route path="/student-profile" element={<StudentProfile />} />
                        <Route path="/ngatregistration" element={<InsufficientPrivileage />} />




                      </>
                    )}

                    {isAuthorized('super_admin') || isAuthorized('support_user')    ? (
                      <>
                        <Route path="/userlist" element={<UserPage />} />

                        <Route path="/registrationrequest" element={<RegistrationRequest />} />


                      </>
                    ) : (
                      <>
                        <Route path="/registrationrequest" element={<InsufficientPrivileage />} />
                        <Route path="/userlist" element={<InsufficientPrivileage />} />

                      </>
                    )}
 

                    {isAuthorized('super_admin') || isAuthorized('officials') || isAuthorized('support_user')  ? (

                      <>
                        <Route path="/maindashobard" element={<MainDashboard />} />
                        <Route path="/allstudentlist" element={<Allstudentpage />} />
                        <Route path="/privatedata" element={<PrivateDataPage />} />
                        <Route path="/publicdata" element={<PublicDataPage />} />
                        <Route path="/institutions" element={<InstitutionPage />} />
                        <Route path="/datasubmission" element={<Datasubmission />} />
                        <Route path="/Re-examregistration" element={<Reexamprogress />} />

                      </>
                    ) : (
                      <>
                        <Route path="/institutions" element={<InsufficientPrivileage />} />
                        <Route path="/allstudentlist" element={<InsufficientPrivileage />} />
                        <Route path="/privatedata" element={<InsufficientPrivileage />} />
                        <Route path="/publicdata" element={<InsufficientPrivileage />} />
                        <Route path="/maindashboard" element={<InsufficientPrivileage />} />
                        <Route path="/datasubmission" element={<InsufficientPrivileage />} />
                        <Route path="/Re-examregistration" element={<InsufficientPrivileage />} />

                      </>
                    )}



                    {isAuthorized('super_admin') || isAuthorized('eta_admin') ? (
                      <>
                        <Route path="/approvallist" element={<ApprovalList />} />
                        <Route path="/etadashboard" element={<EtaDashboard />} />
                        <Route path="/validdata" element={<PrivateDataPage />} />
                        <Route path="/uploadETA" element={<UploadETA />} />


                      </>
                    ) : (
                      <>
                        <Route path="/approvallist" element={<InsufficientPrivileage />} />
                        <Route path="/etadashboard" element={<InsufficientPrivileage />} />
                        <Route path="/validdata" element={<InsufficientPrivileage />} />
                        <Route path="/uploadETA" element={<InsufficientPrivileage />} />
                      </>
                    )}







                    <Route path="*" element={<Navigate replace to="/" />} />
                  </Routes>
                </Box>
              </Box>
            </>
          ) : (
            <Routes>
              {/* Publicly accessible routes */}
              <Route path="/login" element={<Login />} />
              <Route path="/reset-password/:token" element={<ResetPasswordForm />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          )}
        </Router>
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;

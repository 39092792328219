import React, { useEffect, useState } from 'react';
import UserLists from './UserLists'; // Assuming ExamTopics component is created as per previous instructions
import Button from '@mui/material/Button';

const UserPage = () => {
  return (
    <div>
      <h3>User Management</h3>
       <UserLists />
    </div>
  );
};

export default UserPage;

import React, { useState, useEffect } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';
import { useUser } from '.././components/userContext';
import AlertDialog from './AlertDialog'; // Adjust the import path as necessary
 
const ExamCentersTable = () => {
  const user = useUser(); // Access user context

  const [examCenters, setExamCenters] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const fetchExamCenters = async () => {
      const authToken = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://eap.ethernet.edu.et/api/exam-centers', {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Accept': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          setExamCenters(data); // Assuming the response is the array of exam centers
        } else {
          console.error('Failed to fetch exam centers');
        }
      } catch (error) {
        console.error('Error fetching exam centers:', error);
      }
    };

    fetchExamCenters();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (centerId) => {
    console.log(`Edit Center ${centerId}`);
    // Implement edit functionality
  };

  const handleRemove = (centerId) => {
    console.log(`Remove Center ${centerId}`);
    // Implement remove functionality
  };


  return (
    <>
      <TableContainer component={Paper} sx={{ mt: 5 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><b>Name</b></TableCell>
              <TableCell><b>Short Name</b></TableCell>

              <TableCell><b>Region</b></TableCell>

              <TableCell><b>Capacity</b></TableCell>
              {user && (user.role === 'super_admin') && (
                <TableCell><b>Actions</b></TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {examCenters.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((center) => (
              <TableRow key={center.id}>
                <TableCell>{center.name}</TableCell>
                <TableCell>{center.shortname}</TableCell>
                <TableCell>{center.region}</TableCell>

                <TableCell>{center.capacity}</TableCell>
                {user && (user.role === 'super_admin') && (

                  <TableCell>
                    <Button sx={{ mr: 2 }} variant="outlined" onClick={handleOpen} color="primary">
                      Edit
                    </Button>
                    <Button variant="outlined" onClick={handleOpen} color="secondary">
                      Remove
                    </Button>
                  </TableCell>

                )}

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={examCenters.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 25, 50]}
      />

<AlertDialog
        open={open}
        onClose={handleClose}
        title="Temporary Function Disablement"
        message="This function is disabled temporarily."
      />
    </>
  );
};

export default ExamCentersTable;

import React, { useState, useEffect } from 'react';
import { Box, Grid, Container, Typography } from '@mui/material';
import StatisticsCard from '../pages/StatisticsCard';
import PublicIcon from '@mui/icons-material/Public';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SchoolIcon from '@mui/icons-material/School';
import ProgramStudentsCountTableAll from '../pages/ProgramStudentsCountTablAllNgat';
import ExamCenterTable from '../pages/PerExamCenterCountNgat';
import { Pie } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const Dashboard = () => {
    const [stats, setStats] = useState({
        totalRegistered: 0,
        totalMale: 0,
        totalFemale: 0,
        mastersCount: 0,
        phdCount: 0,
        specialityCounts: [],
        examCenterCounts: []
    });

    const fetchStatistics = async () => {
        const authToken = localStorage.getItem('authToken');
        try {
            const response = await fetch('https://ngat.ethernet.edu.et/api/47c0b5683-4e4c-8237-79b6', {
 
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });
            if (response.ok) {
                const data = await response.json();
                setStats(data);
            } else {
                console.error('Failed to fetch statistics');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchStatistics();
        const interval = setInterval(fetchStatistics, 5000); // Refresh every 5 seconds
        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    const formattedValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'ETB',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(stats.totalRegistered * 750);

    const genderData = {
        labels: ['Male', 'Female'],
        datasets: [{
            data: [stats.totalMale, stats.totalFemale],
            backgroundColor: ['#4caf50', '#2196f3'], // Green for male, Blue for female
            borderColor: '#fff',
            borderWidth: 2,
        }],
    };

    return (
        <Box sx={{ flexGrow: 1, mt: 3, ml: 0 }}>
            <Typography variant="h6" gutterBottom>
                            General  Statistics
                        </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 4, ml: 2 }}>
                
                {/* Card for Total Registered */}
                <StatisticsCard
                    title="Total Registered"
                    value={`${stats.totalRegistered}`}
                    icon={<PublicIcon />}
                    color="primary.main"
                />
                {/* Card for Masters Count */}
                <StatisticsCard
                    title="Total applied for Masters"
                    value={`${stats.mastersCount}`}
                    icon={<SchoolIcon />}
                    color="success.main"
                />
                {/* Card for PhD Count */}
                <StatisticsCard
                    title="Total applied for PhD"
                    value={`${stats.phdCount}`}
                    icon={<SchoolIcon />}
                      color="info.main"
                />
                {/* Card for Service Fees */}
                <StatisticsCard
                    title="Service fees"
                    value={formattedValue}
                    icon={<AttachMoneyIcon />}
                    color="warning.main"
                />
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <Container maxWidth={false} sx={{ width: '100%', padding: 0, mt: 2 }}>
                        <ProgramStudentsCountTableAll specialityCounts={stats.specialityCounts} />
                    </Container>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Container maxWidth={false} sx={{ width: '100%', padding: 0, mt: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Gender Statistics
                        </Typography>
                        <div style={{ width: '100%', maxWidth: '260px', margin: '0 auto' }}>
                            <Pie data={genderData} />
                        </div>
                    </Container>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Container maxWidth={false} sx={{ width: '100%', padding: 0, mt: 2 }}>
                        <ExamCenterTable examCenterCounts={stats.examCenterCounts} />
                    </Container>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Dashboard;

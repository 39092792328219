import React, { useState, useEffect } from 'react';
import { Box, Grid, Snackbar, Alert, AlertTitle, Typography, Container } from '@mui/material';
import StatisticsCard from '../pages/StatisticsCard';
import PublicIcon from '@mui/icons-material/Public';
import LockIcon from '@mui/icons-material/Lock';
import SchoolIcon from '@mui/icons-material/School';
import PeopleIcon from '@mui/icons-material/People';
import DataProgressTable from '../pages/dataprogresstable';
import ProgressStatpublic from './inistStatsitcsPublic';
import ProgressStatprivate from './inistStatsitcsPrivate';
import GenderRatio from '../pages/GenderRatioChart';
import PendingPrivateList from '../pages/RecordApprovalList';

const Dashboard = () => {
    const [stats, setStats] = useState({
        totalPublicInstitutions: 0,
        totalPublicInstitutionsWithRecords: 0,
        totalPrivateInstitutions: 0,
        totalPrivateInstitutionsWithRecords: 0,
        numberOfPrograms: 0,
        totalStudents: 0,
        approval_stat: 0,
        type: '',
    });

    useEffect(() => {
        const fetchStatistics = async () => {
            const authToken = localStorage.getItem('authToken');
            try {
                const response = await fetch('https://eap.ethernet.edu.et/api/allstatistics', {
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setStats(data);
                } else {
                    console.error('Failed to fetch statistics');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchStatistics();
    }, []);




    return (
        <Box sx={{ flexGrow: 1, mt: 3, ml: 0 }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 4, ml: 2 }}>
                {/* Card for Total Number of Public Institutions and Those with Records */}
                <StatisticsCard
                    title="Public Institutions"
                    value={`${stats.totalPublicInstitutionsCount} of ${stats.approvedInstitutionsCountPublic} submitted`}
                    icon={<PublicIcon />}
                    color="primary.main"
                />
                {/* Card for Total Number of Private Institutions and Those with Records */}
                <StatisticsCard
                    title="Private Institutions"
                    value={`${stats.totalPrivateInstitutionsCount} of ${stats.approvedInstitutionsCountPrivate} submitted`}
                    icon={<LockIcon />}
                    color="secondary.main"
                />
                {/* Card for Total Number of Programs */}
                <StatisticsCard
                    title="Total Number of Programs"
                    value={stats.numberOfPrograms}
                    icon={<SchoolIcon />}
                    color="info.main"
                />
                {/* Card for Total Number of Students */}
                <StatisticsCard
                    title="Total Number of Students"
                    value={stats.totalStudents}
                    icon={<PeopleIcon />}
                    color="error.main"
                />
            </Box>


            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <DataProgressTable />
                </Grid>
                <Grid item xs={12} md={4}>
                    <GenderRatio maleStudents={stats.maleStudents} femaleStudents={stats.femaleStudents}   title="Gender Distribution (Private and Public Institutions)"  />
                </Grid>
            </Grid>

            <Typography sx={{ ml: 6, mt: 2,mb:5 }} variant="h6"><b>Institutions with no data submissions</b></Typography>

{/* Container for the private stats, no minWidth, using full width */}
<Container maxWidth={false} sx={{ width: '100%', padding: 0, mt: 2 }} xs={12} md={8}    >
  <ProgressStatprivate />
</Container>

{/* Container for the public stats, similarly no minWidth, using full width */}
<Container maxWidth={false} sx={{ width: '100%', padding: 0, mt: 8}} xs={12} md={8}    >
  <ProgressStatpublic />
</Container>


<Typography sx={{ ml: 6, mt: 2,mb:5 ,mt:11}} variant="h6"><b>Private Instutions data pending for approval</b></Typography>

<Container maxWidth={false} sx={{ width: '100%', padding: 0, mt: 5}} xs={12} md={8}    >
  <PendingPrivateList />
</Container>








        </Box>
    );
};

export default Dashboard;

// src/context/UserContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const UserContext = createContext();

const getCurrentUser = async () => {
  const authToken = localStorage.getItem('authToken'); // Assuming 'authToken' is where your token is stored
  try {
    const response = await fetch('https://eap.ethernet.edu.et/api/current_user', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Failed to fetch user');
    }
    const data = await response.json();

    console.log('Feteched user details:', data);
 
    return data; // Assuming the backend returns the user object including the email and role
  } catch (error) {
    console.error('Error fetching current user:', error);
    return null; // Handle error appropriately
  }
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    getCurrentUser().then(setUser);
  }, []);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);

import React, { useState, useEffect } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Snackbar, Alert ,AlertTitle,  CircularProgress,
} from '@mui/material';


const InstitutionsTable = () => {
  const [institutions, setInstitutions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [successMessage, setSuccessMessage] = useState('');

   const [alertSeverity, setAlertSeverity] = useState('success'); // New state for managing alert severity
   const [approvingRequestId, setApprovingRequestId] = useState(null);
   const [declineRequestId, setDecliningngRequestId] = useState(null);
 
  
    const fetchInstitutions = async () => {
      const authToken = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://eap.ethernet.edu.et/api/institutions-with-details-private', {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Accept': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          setInstitutions(data); // Assuming the response is an array of institutions
        } else {
          console.error('Failed to fetch institutions');
        }
      } catch (error) {
        console.error('Error fetching institutions:', error);
      }
    };
    useEffect(() => {
      fetchInstitutions();
  }, []);
   
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseSnackbar = () => {
    setSuccessMessage('');
  };

   


  




  return (
    <>
      <TableContainer component={Paper} sx={{ mt: 5 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><b>Institution Name</b></TableCell>
              <TableCell align="right"><b>Number of students</b></TableCell>
              <TableCell align="right" ><b>Number of Departments</b></TableCell>

              <TableCell align="right" ><b>Student list</b></TableCell>

             </TableRow>
          </TableHead>
          <TableBody>
            {institutions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((institution) => (
              <TableRow key={institution.id}>
                <TableCell component="th" scope="row">
                  {institution.name}
                </TableCell>
                <TableCell align="right">{institution.student_count}</TableCell>
                <TableCell align="right">{institution.department_count}</TableCell>
                <TableCell align="right"  >
                  <Button variant="contained" color="primary" href={institution.download_link}>
                    Download
                  </Button>
                </TableCell>

                
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={institutions.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />

<Snackbar open={Boolean(successMessage)} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={alertSeverity} sx={{ width: '100%' }}>
          <AlertTitle>{alertSeverity.charAt(0).toUpperCase() + alertSeverity.slice(1)}</AlertTitle> {/* Dynamically setting the title based on severity */}
          {successMessage}
        </Alert>
      </Snackbar>
    </>

  );
};

export default InstitutionsTable;

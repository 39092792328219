import React from 'react';
import StudentTable from './StudentTable'; // Adjust the import path as needed
import Button from '@mui/material/Button';

const StudentPage = () => {
  return (
    <div>
         <StudentTable />
    </div>
  );
};

export default StudentPage;

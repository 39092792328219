import React, { useEffect, useState } from 'react';
import ExamCenters from './ExamCenterLists'; // Assuming ExamTopics component is created as per previous instructions
import Button from '@mui/material/Button';
import { useUser } from '.././components/userContext';
import AlertDialog from './AlertDialog'; // Adjust the import path as necessary

const ExamCenterPage = () => {
  const user = useUser(); // Access user context
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <h3>Exam Center Management</h3>
      {user && (user.role === 'super_admin') && (
      <Button onClick={handleOpen} variant="contained">Add New Center</Button>  
    )}      <ExamCenters />

<AlertDialog
        open={open}
        onClose={handleClose}
        title="Temporary Function Disablement"
        message="This function is disabled temporarily."
      />
    </div>
  );
};

export default ExamCenterPage;

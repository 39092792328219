import React, { useState, useEffect } from 'react';
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';
import AlertDialog from './AlertDialog'; // Adjust the import path as necessary

const RoleLists = () => {
  const [examTopics, setExamTopics] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const fetchExamTopics = async () => {
      const authToken = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://eap.ethernet.edu.et/api/role-list', {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Accept': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          setExamTopics(data); // Assuming the response is the array of exam topics
        } else {
          console.error('Failed to fetch exam topics');
        }
      } catch (error) {
        console.error('Error fetching exam topics:', error);
      }
    };

    fetchExamTopics();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (centerId) => {
    console.log(`Edit Center ${centerId}`);
    // Implement edit functionality
  };

  const handleRemove = (centerId) => {
    console.log(`Remove Center ${centerId}`);
    // Implement remove functionality
  };

  return (
    <>
      <TableContainer component={Paper} sx={{mt:5}}>
        <Table sx={{ minWidth: 500, }} aria-label="simple table">
          <TableHead>
            <TableRow>
            <TableCell><b>Role name</b></TableCell>
            <TableCell><b>Action</b></TableCell>
             

            </TableRow>
          </TableHead>
          <TableBody>
            {examTopics.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((topic) => (
              <TableRow key={topic.id}>
                <TableCell>{topic.name}</TableCell>
             
 
                <TableCell>
                <Button sx={{mr:2}} variant="outlined"  onClick={handleOpen}  color="primary">
                  Edit
                </Button>
                <Button  variant="outlined"  onClick={handleOpen}  color="secondary">
                  Remove
                </Button>
              </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={examTopics.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
      <AlertDialog
        open={open}
        onClose={handleClose}
        title="Temporary Function Disablement"
        message="This function is disabled temporarily."
      />
    </>
  );
};

export default RoleLists;

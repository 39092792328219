import React, { useEffect, useState } from 'react';
import { Table,Container, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, TablePagination } from '@mui/material';

const PrivateInstitutionsTable = () => {
  const [institutions, setInstitutions] = useState({
    data: [],
    page: 0,
    totalPages: 0,
    perPage: 5
  });

  useEffect(() => {
    const fetchData = async () => {
      const authToken = localStorage.getItem('authToken');
      try {
        const response = await fetch(`https://eap.ethernet.edu.et/api/inist-stat-private?page=${institutions.page + 1}`, {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        });
        if (response.ok) {
          const jsonData = await response.json();
          setInstitutions(prev => ({
            ...prev,
            data: jsonData.data,
            totalPages: jsonData.total,
            perPage: jsonData.per_page
          }));
        } else {
          console.error("Failed to fetch institutions");
        }
      } catch (error) {
        console.error("Error fetching institutions:", error);
      }
    };
    fetchData();
  }, [institutions.page]);

  const handleChangePage = (event, newPage) => {
    setInstitutions(prev => ({ ...prev, page: newPage }));
  };

  return (

    <Container maxWidth={false} sx={{ width: '100%', padding: 0 }}>   
    <Typography variant="h7" sx={{ my: 2,}}><b>Private Institutions</b></Typography>

    
    
    <TableContainer component={Paper} sx={{ maxWidth: '100%', margin: 'auto', mt: 4 }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell><b>Institution Name</b></TableCell>
            <TableCell align="right"><b>Represenative name</b></TableCell>
            <TableCell align="right"><b>Email</b></TableCell>
            <TableCell align="right"><b>Phone</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {institutions.data.map((institution) => (
            <TableRow key={institution.id}>
              <TableCell component="th" scope="row">{institution.name}</TableCell>
              {institution.users && institution.users.length > 0 ? institution.users.map((user) => (
                <React.Fragment key={user.id}>
                  <TableCell align="right">{user.first_name} </TableCell>
                  <TableCell align="right">{user.email}</TableCell>
                  <TableCell align="right">{user.phone}</TableCell>
                </React.Fragment>
              )) : (
                <>
                  <TableCell align="right">No User Data</TableCell>
                  <TableCell align="right">-</TableCell>
                  <TableCell align="right">-</TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={institutions.totalPages}
        page={institutions.page}
        onPageChange={handleChangePage}
        rowsPerPage={institutions.perPage}
        rowsPerPageOptions={[5]}
      />
    </TableContainer>
    </Container>
  );
};

export default PrivateInstitutionsTable;

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, TextField, Button, Typography, Container, Link, Grid, CircularProgress, Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ResetPasswordForm = () => {
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ open: false, message: '', severity: '' });
    const navigate = useNavigate();

    const onBack = () => {
        navigate('/login'); // Adjust as needed
    };

    const handleResetPassword = async (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            setAlert({ open: true, message: 'Passwords do not match.', severity: 'error' });
            return;
        }
        setLoading(true);
        try {
            const response = await fetch('https://eap.ethernet.edu.et/api/password-reset', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token, password, password_confirmation: confirmPassword }),
            });
            const data = await response.json();
            if (response.ok) {
                setAlert({ open: true, message: 'Password has been successfully reset.', severity: 'success' });
                setTimeout(() => navigate('/login'), 6000); // Redirect after alert
            } else {
                setAlert({ open: true, message: data.message || 'Failed to reset password.', severity: 'error' });
            }
        } catch (error) {
            console.error('Error:', error);
            setAlert({ open: true, message: 'An error occurred.', severity: 'error' });
        }
        setLoading(false);
    };

    const handleCloseSnackbar = () => {
        setAlert({ ...alert, open: false });
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                <Typography component="h1" variant="h5">Reset Password</Typography>
                <Box component="form" noValidate onSubmit={handleResetPassword} sx={{ mt: 1 }}>
                    <TextField margin="normal" required fullWidth name="password" label="New Password" type="password" id="password" autoComplete="new-password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    <TextField margin="normal" required fullWidth name="confirmPassword" label="Confirm New Password" type="password" id="confirmPassword" autoComplete="new-password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : 'Reset Password'}
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link variant="body2" onClick={onBack} style={{ cursor: 'pointer' }}>Back to login</Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={alert.severity} sx={{ width: '100%' }}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default ResetPasswordForm;

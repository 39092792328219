import React, { useEffect, useState } from 'react';
import InstitutionList from './InstitutionList'; // Assuming ExamTopics component is created as per previous instructions
import {Button} from '@mui/material';
 import { Slide, IconButton } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
 import { useUser } from '.././components/userContext';
import WarningIcon from '@mui/icons-material/WarningAmber';
import CloseIcon from '@mui/icons-material/Close';
const ExamTopicsPage = () => {
  const user = useUser(); // Access user context
  const [openDialog, setOpenDialog] = useState(false);

  const handleDialogOpen = () => {
    setOpenDialog(true);
 };

 const handleDialogClose = () => {
   setOpenDialog(false);

  };

  const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
 });


  return (
    <div>
      <h3>Inistitutions </h3>
      {user && (user.role === 'super_admin') && (
      <Button  onClick={() => handleDialogOpen()} variant="contained">Add New Inistitute</Button>  
    )}
      <InstitutionList />

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Slide}
      >
        <DialogTitle id="alert-dialog-title">{"Disabled function"}
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <WarningIcon sx={{ color: 'red', mr: 2, fontSize: 40 }} />
            This fucntion disabled temporarily 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" variant="outlined">
            Ok
          </Button>
          
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ExamTopicsPage;

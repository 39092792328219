import React, { useState, useEffect } from 'react';
import DangerousIcon from '@mui/icons-material/Dangerous';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,

  TablePagination, Snackbar, Alert
} from '@mui/material';


const RegistrationRequestsTable = () => {
  const [registrationRequests, setRegistrationRequests] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });
  const [isApproving, setIsApproving] = useState(false); // New state for tracking approval request
  const [approvingRequestId, setApprovingRequestId] = useState(null);
  const [declineRequestId, setDecliningngRequestId] = useState(null);
  const [email, setEmail] = useState('');

  useEffect(() => {
    fetchRegistrationRequests();
  }, []);


  const fetchRegistrationRequests = async () => {
    const authToken = localStorage.getItem('authToken');
    try {
      const response = await fetch('https://eap.ethernet.edu.et/api/request-list', {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Accept': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data); // Inspect the fetched data
        // Ensure data is an array before setting state
        if (Array.isArray(data)) {
          setRegistrationRequests(data);
        } else {
          console.error('Data is not an array');
        }
      } else {
        console.error('Failed to fetch registration requests');
      }
    } catch (error) {
      console.error('Error fetching registration requests:', error);
    }
  };



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Conditional rendering to handle non-array data
  if (!Array.isArray(registrationRequests)) {
    return <div>Loading or No registration requests found...</div>;
  }

  const handleApprove = async (id) => {
    setApprovingRequestId(id); // Start loading for this specific request

    const authToken = localStorage.getItem('authToken');

    console.log("user id :", id);
    try {
      const response = await fetch(`https://eap.ethernet.edu.et/api/registration-requests/${id}/approve`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Accept': 'application/json',
        },
      });
      if (response.ok) {
        setAlert({ open: true, message: 'Registration request approved successfully.', severity: 'success' });
        fetchRegistrationRequests(); // Refresh the list
      } else {
        setAlert({ open: true, message: 'Failed to approve registration request.', severity: 'error' });
      }
    } catch (error) {
      console.error('Error:', error);
      setAlert({ open: true, message: 'An error occurred.', severity: 'error' });
    }

    finally {
      setApprovingRequestId(null); // Reset loading state
    }
  };


  const handleDecline = async (id) => {
    setDecliningngRequestId(id); // Start loading for this specific request

    const authToken = localStorage.getItem('authToken');

    console.log("user id :", id);
    try {
      const response = await fetch(`https://eap.ethernet.edu.et/api/registration-requests/${id}/decline`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Accept': 'application/json',
        },
      });
      if (response.ok) {
        setAlert({ open: true, message: 'Registration request declined successfully.', severity: 'success' });
        fetchRegistrationRequests(); // Refresh the list
      } else {
        setAlert({ open: true, message: 'Failed to decline registration request.', severity: 'error' });
      }
    } catch (error) {
      console.error('Error:', error);
      setAlert({ open: true, message: 'An error occurred.', severity: 'error' });
    }

    finally {
      setDecliningngRequestId(null); // Reset loading state
    }
  };



  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert({ ...alert, open: false });
  };
  return (
    <>

      <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
      <TableContainer component={Paper} sx={{ mt: 5 }}>
        <Table sx={{ minWidth: 500 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><b>First Name</b></TableCell>
              <TableCell><b>Father Name</b></TableCell>
              <TableCell><b>Grand Father Name</b></TableCell>
              <TableCell><b>Gender</b></TableCell>
              <TableCell><b>Institution</b></TableCell>

              <TableCell><b>Email</b></TableCell>
              <TableCell><b>Phone Number</b></TableCell>
              <TableCell><b>Attachement</b></TableCell>
              <TableCell><b>Actions</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {registrationRequests
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((request) => (
                <TableRow key={request.id}>
                  <TableCell>{request.first_name}</TableCell>
                  <TableCell>{request.father_name}</TableCell>
                  <TableCell>{request.grand_father_name}</TableCell>
                  <TableCell>{request.gender}</TableCell>
                  <TableCell>{request.institution}</TableCell>

                  <TableCell>{request.email}</TableCell>
                  <TableCell>{request.phone}</TableCell>
                  <TableCell>

                    <a href={request.file} target="_blank" rel="noopener noreferrer">
                      Download File
                    </a>

                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="success"
                      sx={{ mr: 2 }}
                      onClick={() => handleApprove(request.id)}
                      disabled={approvingRequestId === request.id} // Disable this button if it's being processed
                    >
                      {approvingRequestId === request.id ? <CircularProgress size={24} /> : 'Approve'}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ mr: 2, backgroundColor: 'red', '&:hover': { backgroundColor: 'darkred' } }}
                      onClick={() => handleDecline(request.id)} // Call handleDecline with the current request's ID
                      disabled={declineRequestId === request.id} // Disable this button if it's being processed

                    >
                      {declineRequestId === request.id ? <CircularProgress size={24} /> : 'Decline'}
                    </Button>
                  </TableCell>

                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={registrationRequests.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </>
  );
};

export default RegistrationRequestsTable;

import React, { useState, useEffect } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Snackbar, Alert ,AlertTitle,  CircularProgress,
} from '@mui/material';
import { TableSortLabel } from '@mui/material';


const InstitutionsTable = () => {
  const [institutions, setInstitutions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [successMessage, setSuccessMessage] = useState('');

   const [alertSeverity, setAlertSeverity] = useState('success'); // New state for managing alert severity
   const [approvingRequestId, setApprovingRequestId] = useState(null);
   const [declineRequestId, setDecliningngRequestId] = useState(null);
 
   const [orderDirection, setOrderDirection] = useState('asc');
const [orderBy, setOrderBy] = useState('name');  // Default to sorting by institution name

  
const handleRequestSort = (property) => {
  const isAsc = orderBy === property && orderDirection === 'asc';
  setOrderDirection(isAsc ? 'desc' : 'asc');
  setOrderBy(property);
};


const sortedData = () => {
  return institutions.sort((a, b) => {
    if (a[orderBy] < b[orderBy]) {
      return orderDirection === 'asc' ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return orderDirection === 'asc' ? 1 : -1;
    }
    return 0;
  });
};


    const fetchInstitutions = async () => {
      const authToken = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://eap.ethernet.edu.et/api/institutions-with-details-public', {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Accept': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          setInstitutions(data); // Assuming the response is an array of institutions
        } else {
          console.error('Failed to fetch institutions');
        }
      } catch (error) {
        console.error('Error fetching institutions:', error);
      }
    };
    useEffect(() => {
      fetchInstitutions();
  }, []);
   
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseSnackbar = () => {
    setSuccessMessage('');
  };

   



  return (
    <>
      <TableContainer component={Paper} sx={{ mt: 5 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
      <TableRow>
        {[
          { id: 'name', label: 'Institution Name' },
          { id: 'student_count', label: 'Number of Students' },
          { id: 'department_count', label: 'Number of Departments' },
          { id: 'download_link', label: 'Student List', sortable: false }
        ].map(headCell => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? orderDirection : false}
          >
            {headCell.sortable === false ? headCell.label : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? orderDirection : 'asc'}
                onClick={() => handleRequestSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
          <TableBody>
          {sortedData()
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((institution) => (
          <TableRow key={institution.id}>
            <TableCell component="th" scope="row">{institution.name}</TableCell>
            <TableCell align="left">{institution.student_count}</TableCell>
            <TableCell align="left">{institution.department_count}</TableCell>
            <TableCell align="left">
              <Button variant="contained" color="primary" href={institution.download_link}>
                Download
              </Button>
            </TableCell>
          </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={institutions.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />

<Snackbar open={Boolean(successMessage)} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={alertSeverity} sx={{ width: '100%' }}>
          <AlertTitle>{alertSeverity.charAt(0).toUpperCase() + alertSeverity.slice(1)}</AlertTitle> {/* Dynamically setting the title based on severity */}
          {successMessage}
        </Alert>
      </Snackbar>
    </>

  );
};

export default InstitutionsTable;

import React, { useState, useEffect } from 'react';
import { Box, Grid, Snackbar, Alert, AlertTitle } from '@mui/material';
import StatisticsCard from '../pages/StatisticsCard';
import PublicIcon from '@mui/icons-material/Public';
import LockIcon from '@mui/icons-material/Lock';
import SchoolIcon from '@mui/icons-material/School';
import PeopleIcon from '@mui/icons-material/People';
import ProgramStudentsCountTableAll from '../pages/ProgramStudentsCountTablAll';
import GenderRatio from '../pages/GenderRatioChart';
import EnrollmentDistribution from '../pages/EtaEnrollmentDistribution';
import ProgramStudentsCountTableEta from '../pages/ProgramsStudetnsCountTableEta';
import ProgramStudentsCountTable from '../pages/ProgramStudentsCountTable';
import InistRatio from './InistutionType';

const Dashboard = () => {
    const [stats, setStats] = useState({
        totalPublicInstitutions: 0,
        totalPublicInstitutionsWithRecords: 0,
        totalPrivateInstitutions: 0,
        totalPrivateInstitutionsWithRecords: 0,
        numberOfPrograms: 0,
        totalStudents: 0,
        approval_stat: 0,
        type: '',
    });

    useEffect(() => {
        const fetchStatistics = async () => {
            const authToken = localStorage.getItem('authToken');
            try {
                const response = await fetch('https://eap.ethernet.edu.et/api/allstatistics', {
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setStats(data);
                } else {
                    console.error('Failed to fetch statistics');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchStatistics();
    }, []);




    return (
        <Box sx={{ flexGrow: 1, mt: 3, ml: 0 }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 4, ml: 2 }}>
                {/* Card for Total Number of Public Institutions and Those with Records */}
                <StatisticsCard
                    title="Public Institutions"
                    value={stats.approvedInstitutionsCountPublic}
                    icon={<PublicIcon />}
                    color="primary.main"
                />
                {/* Card for Total Number of Private Institutions and Those with Records */}
                <StatisticsCard
                    title="Private Institutions"
                    value={stats.approvedInstitutionsCountPrivate}
                    icon={<LockIcon />}
                    color="secondary.main"
                />
                {/* Card for Total Number of Programs */}
                <StatisticsCard
                    title="Total Number of Programs"
                    value={stats.numberOfPrograms}
                    icon={<SchoolIcon />}
                    color="info.main"
                />
                {/* Card for Total Number of Students */}
                <StatisticsCard
                    title="Total Number of Students"
                    value={stats.totalStudents}
                    icon={<PeopleIcon />}
                    color="error.main"
                />
            </Box>


            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>

                    <ProgramStudentsCountTableAll />

                </Grid>

                <Grid item xs={12} md={4}>
                    
                    <InistRatio privateStudents={stats.privateStudents} publicStudents={stats.publicStudents} />

                </Grid>

                <Grid item xs={12} md={8}>

                    <EnrollmentDistribution enrollmentData={stats.enrollmentCounts}  title="Enrollment Distribution (Private and Public Institutions)"/>

                </Grid>
                <Grid item xs={12} md={4}>
                    <GenderRatio maleStudents={stats.maleStudents} femaleStudents={stats.femaleStudents}     title="Gender Distribution (Private and Public Institutions)" 
/>
                </Grid>

                <Grid item xs={12} md={8}>
                    <ProgramStudentsCountTable />
                </Grid>

                <Grid item xs={12} md={4}>
                    <GenderRatio maleStudents={stats.maleStudentsPublic} femaleStudents={stats.femaleStudentsPublic}     title="Gender Distribution (Public Institutions)" 
/>
                </Grid>


                <Grid item xs={12} md={8}>
                    <ProgramStudentsCountTableEta  title="Number of Students per Program (Private Institutions)" />
                </Grid>

                <Grid item xs={12} md={4}>
                <GenderRatio 
    maleStudents={stats.maleStudentsPrivate} 
    femaleStudents={stats.femaleStudentsPrivate} 
    title="Gender Distribution (Private Institutions)" 
/>
                </Grid>


                <EnrollmentDistribution enrollmentData={stats.enrollmentCountsPublic}  title="Enrollment Distribution (Public Institutions)"/>
                <EnrollmentDistribution enrollmentData={stats.enrollmentCountsPrivate}  title="Enrollment Distribution (Private Institutions)"/>

            </Grid>



        </Box>
    );
};

export default Dashboard;

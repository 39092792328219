import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    TablePagination,
    Container,
    Box,
} from '@mui/material';

const UserLogTable = () => {
    const [userLogs, setUserLogs] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [search, setSearch] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const authToken = localStorage.getItem('authToken');
            const response = await fetch(
                `https://eap.ethernet.edu.et/api/user-logs?search=${search}&page=${page + 1}`,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                }
            );
            if (response.ok) {
                const jsonData = await response.json();
                setUserLogs(jsonData.data);
                setTotal(jsonData.total);
                setPerPage(jsonData.per_page);
            } else {
                console.error('Failed to fetch user logs');
            }
        };
        fetchData();
    }, [search, page]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    return (
        <Container maxWidth="false" disableGutters>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                <TextField
                    value={search}
                    onChange={handleSearchChange}
                    placeholder="Search by user name"
                    size="small"
                    sx={{ width: 250 }}
                />
            </Box>

            <TableContainer component={Paper} sx={{ width: '100%', maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <b>User</b>
                            </TableCell>
                            <TableCell>
                                <b>Action</b>
                            </TableCell>
                            <TableCell>
                                <b>Timestamp</b>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userLogs.map((log) => (
                            <TableRow key={log.id}>
                                <TableCell>{log.user}</TableCell>
                                <TableCell>{log.action}</TableCell>
                                <TableCell>{log.timestamp}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={total}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={perPage}
                />
            </TableContainer>
        </Container>
    );
};

export default UserLogTable;

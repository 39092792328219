import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Liberation Serif', // Your desired font
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  // other theme settings...

  palette: {
    primary: {
      main: 'rgb(18,82,166)', // Set your desired color for primary palette
    },
    // You can also adjust other theme settings as needed
  },
});



export default theme;

import React, { useState, useContext, useEffect } from 'react';
import { Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Paper, Box, Grid, Typography, ThemeProvider, CircularProgress } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext'; // Ensure correct path
import logo from '../moe-logo.png'; // Ensure correct path
import SignUp from './SignUp'; // Ensure correct path
import ForgetPassword from './ForgetPassword'; // Ensure you have created this component

const defaultTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            height: '20px',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          height: '20px',
        },
      },
    },
    MuiCircularProgress: { // Styling for CircularProgress component
      styleOverrides: {
        root: {
          color: 'white', // Change color to white
          strokeWidth: '4px', // Increase stroke width
        },
      },
    },
  },
  palette: {
    primary: {
      main: 'rgb(18,82,166)',
    },
  },
  typography: {
    fontFamily: '"Times New Roman", Times, serif',
  },
});

export default function SignInSide() {
  const navigate = useNavigate();
  const { updateAuthStatus } = useContext(AuthContext);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const [loading, setLoading] = useState(false); // State to manage loading state of the button
  const [error, setError] = useState(''); // New state for managing login error
  const [loginSuccessful, setLoginSuccessful] = useState(false); // New local state

  const handleSignInSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Set loading state to true when sign-in is clicked
    const data = new FormData(event.currentTarget);
    data.append('email', data.get('email'));
    data.append('password', data.get('password'));

    try {
      const response = await fetch('https://eap.ethernet.edu.et/api/login', {
        method: 'POST',
        headers: { 'Accept': 'application/json' },
        body: data,
      });

      if (response.ok) {
        const userData = await response.json();
        localStorage.setItem('authToken', userData.token); // Store the token
        updateAuthStatus(true); // Update authentication status in context
        setLoginSuccessful(true); // Update local state to trigger redirection
      } else {
        const errorData = await response.json();
        setError(errorData.message); // Set the error message
      }
    } catch (error) {
      console.error('Login error:', error);
    } finally {
      setLoading(false); // Reset loading state to false after response
    }
  };

  useEffect(() => {
    if (loginSuccessful) {
      window.location.href = '/';
    }
  }, [loginSuccessful, navigate]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item xs={false} sm={4} md={7}
          sx={{ backgroundColor: 'primary.main', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '-400px' }}>
          <Box sx={{ textAlign: 'center' }}>
            <img src={logo} alt="Ministry of Education Logo" style={{ maxWidth: '100px', marginBottom: '20px' }} />
            <Typography variant="h6" component="h1" sx={{ color: '#fff', fontFamily: '"Times New Roman", Times, serif' }}>በኢ.ፌ.ዲ.ሪ የትምህርት ሚኒስቴር</Typography>
            <Typography variant="subtitle1" sx={{ color: '#fff', marginBottom: '20px', fontFamily: '"Times New Roman", Times, serif' }}>FDRE, Ministry Of Education</Typography>
            <Typography variant="subtitle1" sx={{ color: '#fff', marginBottom: '20px', fontFamily: '"Times New Roman", Times, serif' }}>Student Exit Exam Data Hub</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          {!showSignUp && !showForgetPassword ? (
            // Display the SignIn form
            <Box sx={{ my: 8, mx: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography component="h1" variant="h5">Sign in</Typography>
              <Box component="form" noValidate onSubmit={handleSignInSubmit} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  error={!!error} // This will be true if there is an error
                  helperText={error} // Display the error message
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  error={!!error} // This will be true if there is an error
                />
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                  {loading ? <CircularProgress size={24} /> : 'Sign In'} {/* Display CircularProgress if loading is true */}
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link variant="body2" onClick={() => setShowForgetPassword(true)} style={{ cursor: 'pointer' }}>Forgot password?</Link>
                  </Grid>
                  <Grid item>
                    <Link variant="body2" onClick={() => setShowSignUp(true)} style={{ cursor: 'pointer' }}>{"Don't have an account? Sign Up"}</Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          ) : showSignUp ? (
            // Display the SignUp component
            <SignUp />
          ) : (
            // Display the ForgetPassword component
            <ForgetPassword />
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

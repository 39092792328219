import React from 'react';
import { Typography, Container } from '@mui/material';

const InsufficientPrivilege = () => {
  return (
    <Container maxWidth="sm" sx={{ marginTop: '2rem' }}>
      <Typography variant="h4" align="center" gutterBottom>
        Insufficient Privilege
      </Typography>
      <Typography variant="body1" align="center">
        You do not have sufficient privilege to access this page.
      </Typography>
    </Container>
  );
};

export default InsufficientPrivilege;

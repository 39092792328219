import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    TableSortLabel,
    Typography,
    Box
} from '@mui/material';

const ProgramStudentsCountTableAll = ({ specialityCounts }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('speciality');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedSpecialityCounts = specialityCounts.sort((a, b) => {
        if (orderBy === 'speciality') {
            return (order === 'asc' ? a.speciality.localeCompare(b.speciality) : b.speciality.localeCompare(a.speciality));
        } else {
            return (order === 'asc' ? a.count - b.count : b.count - a.count);
        }
    });

    const displayedRows = sortedSpecialityCounts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                Per speciality Statistics
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'speciality'}
                                    direction={orderBy === 'speciality' ? order : 'asc'}
                                    onClick={() => handleRequestSort('speciality')}
                                >
                                    Speciality catagory
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={orderBy === 'count'}
                                    direction={orderBy === 'count' ? order : 'asc'}
                                    onClick={() => handleRequestSort('count')}
                                >
                                    Number of examinees registered
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayedRows.map((speciality) => (
                            <TableRow key={speciality.speciality} hover>
                                <TableCell component="th" scope="row">
                                    {speciality.speciality}
                                </TableCell>
                                <TableCell align="right">{speciality.count}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={specialityCounts.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </Box>
    );
};

export default ProgramStudentsCountTableAll;

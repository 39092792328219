import React, { useState, useContext } from 'react';
import { TextField, Button, Typography, Container, Link, Grid, Snackbar, Alert, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext'; // Ensure correct path

const ForgetPassword = () => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ open: false, message: '', severity: 'info' }); // Renamed from alert to alertMessage
    const [emailError, setEmailError] = useState('');

    const onBack = () => {
        navigate('/'); // Navigate back+
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setEmailError('');

        // API call
        const response = await fetch('https://eap.ethernet.edu.et/api/password-reset-request', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email }),
        });

        setLoading(false);

        if (response.ok) {
            setAlertMessage({ open: true, message: 'Password reest link has been successfully sent to your email, please follow the instruction there', severity: 'success' });
        } else if (response.status === 404) {
            setEmailError('We didn\'t find this email'); 
        } else {
            setAlertMessage({ open: true, message: 'Failed to send password reset link.', severity: 'error' });
        }
    };

    const handleCloseSnackbar = () => {
        setAlertMessage({ ...alertMessage, open: false });
    };

    return (
        <Container maxWidth="xs">
            <Container sx={{ mt: 20 }}>
                <Typography sx={{ ml: 10 }} variant="h6">Forget Password</Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        size="medium"
                        margin="normal"
                        required
                        fullWidth
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={!!emailError}
                        helperText={emailError}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Send Password Reset Link'}
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link variant="body2" onClick={onBack} style={{ cursor: 'pointer' }}>Back to login</Link>
                        </Grid>
                    </Grid>
                </form>
            </Container>

            <Snackbar open={alertMessage.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={alertMessage.severity} sx={{ width: '100%' }}>
                    {alertMessage.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default ForgetPassword;

import React, { useEffect, useState } from 'react';
import PublicDataList from './PublicDataList'; // Assuming ExamTopics component is created as per previous instructions
import Button from '@mui/material/Button';

const UserPage = () => {
  return (
    <div>
      <h3>Records for all valid public institution data</h3>
       <PublicDataList />
    </div>
  );
};

export default UserPage;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Box, Paper, Grid } from '@mui/material';

const StudentProfile = () => {
    const { id } = useParams();
    const [student, setStudent] = useState(null);

    useEffect(() => {
        const fetchStudent = async () => {
            const authToken = localStorage.getItem('authToken');
            const response = await fetch(`https://eap.ethernet.edu.et/api/getStudents/${id}`, {

 
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });
            if (response.ok) {
                const jsonData = await response.json();
                setStudent(jsonData);
            } else {
                console.error("Failed to fetch student");
            }
        };
        fetchStudent();
    }, [id]);

    if (!student) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <Container>
            <Paper sx={{ p: 3, mt: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Student Profile
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6">First Name:</Typography>
                        <Typography>{student.first_name}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Father Name:</Typography>
                        <Typography>{student.father_name}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Grand Father Name:</Typography>
                        <Typography>{student.grand_father_name}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Gender:</Typography>
                        <Typography>{student.gender}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Enrollment Type:</Typography>
                        <Typography>{student.enrollment}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Institution Name:</Typography>
                        <Typography>{student.institution_name}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Department:</Typography>
                        <Typography>{student.department}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Exam Topic:</Typography>
                        <Typography>{student.exam_topic_name}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Exam Center:</Typography>
                        <Typography>{student.exam_center_name}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Phone Number:</Typography>
                        <Typography>{student.phone_number}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Latest GPA:</Typography>
                        <Typography>{student.latest_gpa}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Admission Year:</Typography>
                        <Typography>{student.admission_year}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Admission Month:</Typography>
                        <Typography>{student.admission_month}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Is Blind:</Typography>
                        <Typography>{student.is_blind ? 'Yes' : 'No'}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Is Deaf:</Typography>
                        <Typography>{student.is_deaf ? 'Yes' : 'No'}</Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default StudentProfile;

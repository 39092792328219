import React from 'react';
import { PieChart, Pie, Legend, Tooltip, Cell, } from 'recharts';
import { Container ,Typography} from '@mui/material';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
 
const InistutionType = ({ publicStudents, privateStudents }) => {
    const data = [
        { name: 'Public', value: publicStudents, color: '#0288D1' }, // Orange color for male
        { name: 'Private', value: privateStudents, color: '#008000' }, // Green color for female
    ];

    return (
        <Container maxWidth="lg" sx={{ ml: 0, mt: 10, width: 750, }} >

            <Typography ml={7} variant="h7">
           <b> Number of students per instution type (Public and Private)  </b>

            </Typography>
            <PieChart width={400} height={350} >
                <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={data}
                    cx={200}
                    cy={200}
                    outerRadius={80}
                    label
                >
                    {
                        data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                        ))
                    }
                </Pie>
                <Tooltip />
                <Legend />
            </PieChart>
        </Container>
    );
};

export default InistutionType;

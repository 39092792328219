// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [authStatus, setAuthStatus] = useState(() => !!localStorage.getItem('authToken'));

    const updateAuthStatus = (status) => {
        setAuthStatus(status);
    };

    useEffect(() => {
        // Optionally, listen for changes in localStorage to update auth status
    }, []);

    return (
        <AuthContext.Provider value={{ authStatus, updateAuthStatus }}>
            {children}
        </AuthContext.Provider>
    );
};

import React, { useEffect, useState } from 'react';
import InistitutionsApprovalList from './RecordApprovalList'; // Assuming ExamTopics component is created as per previous instructions
import Button from '@mui/material/Button';

const UserPage = () => {
  return (
    <div>
      <h3>Records Pending for review</h3>
       <InistitutionsApprovalList />
    </div>
  );
};

export default UserPage;

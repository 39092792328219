import React, { useState, useEffect } from 'react';
import { Box, Alert, AlertTitle, Grid } from '@mui/material';
import StatisticsCard from './StatisticsCard';
import PeopleIcon from '@mui/icons-material/People';
import SchoolIcon from '@mui/icons-material/School';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import ProgramStudentsCountTable from './ProgramStudentsCountTableInist'; 
import EnrollmentDistribution from './EtaEnrollmentDistribution';

const Dashboard = () => {
  const [stats, setStats] = useState({
    totalStudents: 0,
    numberOfPrograms: 0,
    maleStudents: 0,
    femaleStudents: 0,
    approval_stat: 0,
    submission_count: 0, // Ensure this state is being updated appropriately
  });

  useEffect(() => {
    const fetchStatistics = async () => {
      const authToken = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://eap.ethernet.edu.et/api/statistics', {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          setStats(data);
          console.log("Fetched Data:", data); // Debugging fetched data
        } else {
          console.error('Failed to fetch statistics');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchStatistics();
  }, []);

  const getAlertMessage = () => {
    if (stats.type === "Private") {
      if (stats.totalStudents === 0 && stats.approval_stat === 0) {
        return {
          severity: "info",
          title: "No Submission found",
          message: "There is no submission found yet, go and start by uploading records in Upload Data section."
        };
      }
      switch (stats.approval_stat) {
        case 0:
            // Return null if the submission count is 2 or more
            if (stats.submission_count >= 2) {
                return null;
            }
            // Otherwise, return an "info" alert if the submission count is less than 2
            return {
                severity: "info",
                title: "Pending",
                message: "Your submission is pending for approval. Please follow up with ETA."
            };
        case 1:
            return {
                severity: "success",
                title: "Approved",
                message: "Your records have been approved by ETA. You will not be able to submit once it's approved. \n\n - You can see your student lists in the list section."
            };
        case 2:
            return {
                severity: "warning",
                title: "Rejected",
                message: "Your record submission has been rejected. If you think this is a mistake, please contact ETA ASAP."
            };
        default:
            return null;
    }
    
    }
  };

  const getAlertMessageReason = () => {
    if (stats.type === "Private" && stats.approval_stat === 2) {
      return {
        severity: "info",
        title: "Rejection reasons :",
        message: `${stats.rejectionReason}..`
      };
    }
    
    return null;
  };

  const getSubmissionAlert = () => {
    // Only show these alerts if the institution type is "Private"
    if (stats.type === "Private") {
      
      console.log("Submission Count:", stats.submission_count); // Debugging the submission count state value
      if (stats.approval_stat !== 1) {

      switch (stats.submission_count ) {
        case 0:
          return {
            severity: "info",
            title: "Initial Submission",
            message: "You have two attempts remaining for re-upload after review by ETA."
          };
        case 1:
          return {
            severity: "warning",
            title: "Second Submission",
            message: "You have only one remaining attempt to submit your records."
          };
        case 2:
        default:
          return {
            severity: "error",
            title: "Final Submission",
            message: "You have exhausted all your attempts; further submissions are not permitted."
          };
      }
    }
    }
    return null; // Return null if the institution type is not "Private"
};

  const submissionAlert = getSubmissionAlert();

  const alertMessage = getAlertMessage();
  const alertMessageReason = getAlertMessageReason();

  return (
    <Box sx={{ flexGrow: 1, mt: 3, ml: 0 }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 4, ml: 2 }}>
        <StatisticsCard title="Total number of Students" value={stats.totalStudents} icon={<PeopleIcon />} color="primary.main" />
        <StatisticsCard title="Total Number of Programs" value={stats.numberOfPrograms} icon={<SchoolIcon />} color="secondary.main" />
        <StatisticsCard title="Total Number of Male Students" value={stats.maleStudents} icon={<MaleIcon />} color="info.main" />
        <StatisticsCard title="Total Number of Female Students" value={stats.femaleStudents} icon={<FemaleIcon />} color="error.main" />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ProgramStudentsCountTable />
        </Grid>
        <Grid item xs={12} md={6}>
          <EnrollmentDistribution enrollmentData={stats.enrollmentCounts} />
        </Grid>
      </Grid>

      {submissionAlert && (
        <Alert severity={submissionAlert.severity} sx={{ width: '50%', ml: 3 }}>
          <AlertTitle>{submissionAlert.title}</AlertTitle>
          <div style={{ whiteSpace: 'pre-line' }}>{submissionAlert.message}</div>
        </Alert>
      )}

      {alertMessage && (
        <Alert severity={alertMessage.severity} sx={{ width: '50%', ml: 3,mt:6 }}>
          <AlertTitle>{alertMessage.title}</AlertTitle>
          <div style={{ whiteSpace: 'pre-line' }}>{alertMessage.message}</div>
        </Alert>
      )}

      {alertMessageReason && (
        <Alert severity={alertMessageReason.severity} sx={{ width: '50%', ml: 3, mt: 6 }}>
          <AlertTitle>{alertMessageReason.title}</AlertTitle>
          <div style={{ whiteSpace: 'pre-line' }}>{alertMessageReason.message}</div>
        </Alert>
      )}
    </Box>
  );
};

export default Dashboard;
